import React, { createContext, useState, useContext } from 'react';

const ItemsContext = createContext();

export function ItemsProvider({ children }) {
  const [items, setItems] = useState([]);
  const [searchParams, setSearchParams] = useState({
    item: "",
  });

  return (
    <ItemsContext.Provider value={{ 
      items, 
      setItems,
      searchParams,
      setSearchParams
    }}>
      {children}
    </ItemsContext.Provider>
  );
}

export function useItems() {
  return useContext(ItemsContext);
} 