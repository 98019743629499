import React from "react";
import { Link } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Toolbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

const Sidebar = ({ sidebarOpen, toggleSidebar, handleLogout }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={sidebarOpen}
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "& .MuiTypography-root": {
            fontSize: "0.875rem",
          },
          "& .MuiListItemText-primary": {
            fontSize: "0.875rem",
          },
          "& .MuiAccordionSummary-content .MuiTypography-root": {
            fontWeight: 500,
          }
        }}
      >
        <List sx={{ flexGrow: 1 }}>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Inbound</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem button component={Link} to="/purchaseOrdersScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Purchase Orders" />
                </ListItem>
                <ListItem button component={Link} to="/receiving">
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Receiving" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Storage</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem button component={Link} to="/areasScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Areas" />
                </ListItem>
                <ListItem button component={Link} to="/locationsScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </ListItem>
                <ListItem button component={Link} to="/itemGroupsScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Item Groups" />
                </ListItem>
                <ListItem button component={Link} to="/itemsScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Items" />
                </ListItem>
                
                <ListItem button component={Link} to="/inventory">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Inventory" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Outbound</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem button component={Link} to="/itemsScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Items" />
                </ListItem>
                <ListItem button component={Link} to="/locationsScreen">
                  <ListItemIcon>
                    <InventoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </ListItem>
                <ListItem button component={Link} to="/receiving">
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Receiving" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </List>
        <ListItem button component={Link} to="/settingsScreen">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
