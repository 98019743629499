import React, { useState } from "react";
import { Notification, notify } from "../Components/Notification";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { useAreas } from "../Context/AreasContext";
import UpdateDialog from "../Components/UpdateDialog";
import CreateDialog from "../Components/CreateDialog";
import api from '../services/api';

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "75vh",
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#f5f2f2",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#eef2f6",
    cursor: "pointer",
  },
  "& .MuiTableRow-root.selected": {
    backgroundColor: "#bfbdbd !important",
  },
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    height: "25px",
    fontSize: "0.9rem",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    backgroundColor: "#ffffff",
    color: "#525151",
    fontSize: "1rem",
    fontWeight: "bold",
    height: "35px",
  }
});

function AreasScreen() {
  const {
    areas,
    setAreas,
    searchParams,
    setSearchParams,
  } = useAreas();

  const [selectedRow, setSelectedRow] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [areaToUpdate, setAreaToUpdate] = useState({
    area: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRowClick = (id) => {
    setSelectedRow(id);
  };

  const handleUpdate = async () => {
    const { area, description } = areaToUpdate;
    try {
      await api.updateArea(area, description);
      const updatedAreas = areas.map((oldArea) =>
        oldArea.area === area ? areaToUpdate : oldArea
      );
      setAreas(updatedAreas);
      setUpdateDialogOpen(false);
      setSelectedRow(null);
      notify("Area has been updated", "success", 3000);
    } catch (error) {
      console.error("Error updating area:", error);
      notify("Failed to update area", "error", 3000);
    }
  };

  const handleCreate = async (newArea) => {
    try {
      await api.createArea(newArea.area, newArea.description);
      const response = await api.getAreas();
      setAreas(response.data);
      setCreateDialogOpen(false);
      notify("Area created successfully", "success", 3000);
    } catch (error) {
      console.error("Error creating area:", error);
      notify("Failed to create area", "error", 3000);
    }
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await api.deleteArea(selectedRow);
        setAreas(areas.filter(area => area.area !== selectedRow));
        setSelectedRow(null);
        notify("Area deleted successfully", "success", 3000);
      } catch (error) {
        console.error("Error deleting area:", error);
        notify("Failed to delete area", "error", 3000);
      }
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await api.getAreas(searchParams.area);
      setAreas(response.data);
      if (response.data.length === 0) {
        notify("No areas found", "info", 3000);
      }
    } catch (error) {
      console.error("Error fetching areas:", error);
      notify("Failed to fetch areas data", "error", 3000);
    }
  };

  const clearAreas = () => {
    setAreas([]);
    setSearchParams({ area: "" });
    notify("Areas cleared!", "success", 1000);
  };

  return (
    <Container maxWidth="lx">
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
      >
        <Typography
          variant="h6"
          sx={{
            color: "#525151",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Areas
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            label="Area"
            name="area"
            value={searchParams.area}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={fetchAreas}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Find
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={clearAreas}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Clear
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              if (selectedRow !== null) {
                const foundArea = areas.find((area) => area.area === selectedRow);
                if (foundArea) {
                  setAreaToUpdate(foundArea);
                  setUpdateDialogOpen(true);
                }
              }
            }}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Modify
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleDelete}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Delete
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => setCreateDialogOpen(true)}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Create
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Area</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {areas.map((row) => (
                <TableRow
                  key={row.area}
                  onClick={() => handleRowClick(row.area)}
                  className={selectedRow === row.area ? 'selected' : ''}
                >
                  <TableCell>{row.area}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>

      <UpdateDialog
        open={updateDialogOpen}
        onClose={() => {
          setUpdateDialogOpen(false);
          setAreaToUpdate({ area: "", description: "" });
        }}
        title="Update Area"
        contentText="Please update the area information below:"
        fields={[
          {
            label: "Area",
            name: "area",
            value: areaToUpdate.area,
            disabled: true
          },
          {
            label: "Description",
            name: "description",
            value: areaToUpdate.description
          },
        ]}
        handleInputChange={(e) =>
          setAreaToUpdate({ ...areaToUpdate, [e.target.name]: e.target.value })
        }
        handleSubmit={handleUpdate}
      />

      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        title="Create Area"
        contentText="Please enter the area information below:"
        fields={[
          {
            label: "Area",
            name: "area",
            required: true,
            style: { marginRight: "10px" }
          },
          {
            label: "Description",
            name: "description",
            required: true
          },
        ]}
        handleCreate={handleCreate}
      />

      <Notification />
    </Container>
  );
}

export default AreasScreen;
