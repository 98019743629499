import React, { useState, useEffect } from "react";
import { Notification, notify } from "../Components/Notification";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import { Button, Container, Box, Switch } from "@mui/material";
import api from '../services/api';

function SettingsScreen() {
  const [settings, setSettings] = useState([]);
  const [modifiedSettings, setModifiedSettings] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await api.getSettings();
      console.log('Settings response:', response.data);
      setSettings(response.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
      notify("Failed to fetch settings", "error", 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleToggle = (index, value) => {
    const setting = settings[index];
    if (!setting) return;

    // Update the settings state
    const newSettings = settings.map((s, i) => 
      i === index ? { ...s, is_enabled: value ? 1 : 0 } : s
    );
    setSettings(newSettings);

    // Track modified settings
    setModifiedSettings(prev => ({
      ...prev,
      [setting.name]: value ? 1 : 0
    }));
  };

  const saveChanges = async () => {
    try {
      for (const [name, is_enabled] of Object.entries(modifiedSettings)) {
        await api.updateSetting(name, is_enabled);
      }
      setModifiedSettings({});
      notify("Settings saved successfully", "success", 3000);
      await fetchSettings(); // Refresh settings from the server
    } catch (error) {
      console.error("Error saving settings:", error);
      notify("Failed to save settings", "error", 3000);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={saveChanges}
          disabled={Object.keys(modifiedSettings).length === 0}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
        >
          Save
        </Button>
      </Box>

      <Box
        sx={{
          height: 600,
          width: "100%",
          backgroundColor: "#f5f5f5",
          padding: 2,
          borderRadius: 2,
        }}
      >
        <DataGrid
          rows={settings.map((setting, index) => ({
            ...setting,
            id: index,
          }))}
          columns={[
            {
              field: "name",
              headerName: "Setting Name",
              flex: 1,
            },
            {
              field: "is_enabled",
              headerName: "Enabled",
              flex: 1,
              renderCell: (params) => (
                <Switch
                  checked={params.row.is_enabled === 1}
                  onChange={(event) => {
                    event.stopPropagation();
                    handleToggle(params.row.id, event.target.checked);
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              ),
            },
          ]}
          loading={loading}
          disableSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"
          }
        />
      </Box>
      <Notification />
    </Container>
  );
}

export default SettingsScreen;
