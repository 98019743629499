import React, { useState } from "react";
import { useItemGroups } from "../Context/ItemGroupsContext";
import { Notification } from "../Components/Notification";
import { styled } from "@mui/system";
import api from '../services/api';
import {
  Button,
  Container,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";
import ItemGroupUpdateDialog from "../Components/ItemGroupUpdateDialog";
import ItemGroupCreationDialog from "../Components/ItemGroupCreationDialog";

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "75vh",
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#f5f2f2",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#eef2f6",
    cursor: "pointer",
  },
  "& .MuiTableRow-root.selected": {
    backgroundColor: "#bfbdbd !important",
  },
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    height: "25px",
    fontSize: "0.9rem",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    backgroundColor: "#ffffff",
    color: "#525151",
    fontSize: "1rem",
    fontWeight: "bold",
    height: "35px",
  }
});

function ItemGroups() {
  const { itemGroups, setItemGroups, searchParams, setSearchParams } = useItemGroups();
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [itemGroupToUpdate, setItemGroupToUpdate] = useState({
    item_group: "",
    description: "",
    area1: "",
    area2: "",
    mod_date: ""
  });
  const [newItemGroup, setNewItemGroup] = useState({
    item_group: "",
    description: "",
    area1: "",
    area2: "",
    mod_date: new Date().toISOString().split('T')[0]
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);
  const [areas, setAreas] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const fetchItemGroups = async () => {
    try {
      const response = await api.getItemGroups(searchParams.group);
      setItemGroups(response.data);
    } catch (error) {
      console.error("Error fetching item groups:", error);
    }
  };

  const clearItemGroups = () => {
    setItemGroups([]);
    setSearchParams({ group: "" });
    setSelectedRow(null);
  };

  const handleRowClick = (id) => {
    setSelectedRow(id);
  };

  const handleModify = () => {
    if (selectedRow !== null) {
      const foundGroup = itemGroups.find((group) => group.item_group === selectedRow);
      if (foundGroup) {
        setItemGroupToUpdate(foundGroup);
        setUpdateDialogOpen(true);
        fetchAreas();
      }
    }
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await api.deleteItemGroup(selectedRow);
        setItemGroups(prev => prev.filter(group => group.item_group !== selectedRow));
        setSelectedRow(null);
      } catch (error) {
        console.error("Error deleting item group:", error);
      }
    }
  };

  const handleUpdate = async () => {
    try {
      await api.updateItemGroup(
        itemGroupToUpdate.item_group,
        itemGroupToUpdate.description,
        itemGroupToUpdate.area1,
        itemGroupToUpdate.area2,
        itemGroupToUpdate.mod_date
      );
      await fetchItemGroups();
      setUpdateDialogOpen(false);
      setSelectedRow(null);
    } catch (error) {
      console.error("Error updating item group:", error);
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setItemGroupToUpdate(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setNewItemGroup(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      await api.createItemGroup(newItemGroup);
      await fetchItemGroups();
      setCreateDialogOpen(false);
      setNewItemGroup({
        item_group: "",
        description: "",
        area1: "",
        area2: "",
        mod_date: new Date().toISOString().split('T')[0]
      });
    } catch (error) {
      console.error("Error creating item group:", error);
    }
  };

  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
    setItemGroupToUpdate({
      item_group: "",
      description: "",
      area1: "",
      area2: "",
      mod_date: ""
    });
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
    setNewItemGroup({
      item_group: "",
      description: "",
      area1: "",
      area2: "",
      mod_date: new Date().toISOString().split('T')[0]
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchAreas = async () => {
    try {
      const response = await api.getAreas();
      setAreas(response.data);
    } catch (error) {
      console.error("Error fetching areas:", error);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
    fetchAreas();
  };

  return (
    <Container maxWidth="lx">
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
      >
        <Typography
          variant="h6"
          sx={{
            color: "#525151",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Item Groups
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            label="Group"
            name="group"
            value={searchParams.group}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={fetchItemGroups}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Find
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={clearItemGroups}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Clear
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleModify}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Modify
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleDelete}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Delete
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleCreate}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Create
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Group</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Area 1</TableCell>
                <TableCell>Area 2</TableCell>
                <TableCell>Modified Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemGroups
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.item_group}
                    onClick={() => handleRowClick(row.item_group)}
                    className={selectedRow === row.item_group ? 'selected' : ''}
                  >
                    <TableCell>{row.item_group}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.area1}</TableCell>
                    <TableCell>{row.area2}</TableCell>
                    <TableCell>{row.mod_date}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={itemGroups.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            sx={{
              backgroundColor: "#ffffff",
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                margin: 0,
              }
            }}
          />
        </StyledTableContainer>
      </Box>

      {/* Keep your existing dialogs */}
      <ItemGroupUpdateDialog
        open={updateDialogOpen}
        onClose={handleCloseUpdateDialog}
        itemGroupToUpdate={itemGroupToUpdate}
        handleInputChange={handleUpdateInputChange}
        handleUpdate={handleUpdate}
        areas={areas}
      />

      <ItemGroupCreationDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        newItemGroup={newItemGroup}
        handleInputChange={handleCreateInputChange}
        handleSubmit={handleSubmit}
        areas={areas}
      />

      <Notification />
    </Container>
  );
}

export default ItemGroups;
