import React, { createContext, useState, useContext } from 'react';

const AreasContext = createContext();

export function AreasProvider({ children }) {
  const [areas, setAreas] = useState([]);
  const [searchParams, setSearchParams] = useState({
    area: "",
  });

  return (
    <AreasContext.Provider value={{ 
      areas, 
      setAreas,
      searchParams,
      setSearchParams
    }}>
      {children}
    </AreasContext.Provider>
  );
}

export function useAreas() {
  const context = useContext(AreasContext);
  if (!context) {
    throw new Error('useAreas must be used within an AreasProvider');
  }
  return context;
} 