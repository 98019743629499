import React, { createContext, useState, useContext } from 'react';

const InventoryContext = createContext();

export function InventoryProvider({ children }) {
  const [inventory, setInventory] = useState([]);
  const [searchParams, setSearchParams] = useState({
    item: "",
    location: "",
  });

  return (
    <InventoryContext.Provider value={{ 
      inventory, 
      setInventory,
      searchParams,
      setSearchParams
    }}>
      {children}
    </InventoryContext.Provider>
  );
}

export function useInventory() {
  const context = useContext(InventoryContext);
  if (!context) {
    throw new Error('useInventory must be used within an InventoryProvider');
  }
  return context;
} 