import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';

const ItemGroupUpdateDialog = ({
  open,
  onClose,
  itemGroupToUpdate,
  handleInputChange,
  handleUpdate,
  areas,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Item Group</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Group"
          name="item_group"
          value={itemGroupToUpdate?.item_group || ''}
          onChange={handleInputChange}
          fullWidth
          disabled
          size="small"
        />
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={itemGroupToUpdate?.description || ''}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />
        <TextField
          margin="dense"
          label="Area 1"
          name="area1"
          value={itemGroupToUpdate?.area1 || ''}
          onChange={handleInputChange}
          select
          fullWidth
          size="small"
        >
          {areas.map((area) => (
            <MenuItem key={area.area} value={area.area}>
              {area.area}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          label="Area 2"
          name="area2"
          value={itemGroupToUpdate?.area2 || ''}
          onChange={handleInputChange}
          select
          fullWidth
          size="small"
        >
          <MenuItem value="">None</MenuItem>
          {areas.map((area) => (
            <MenuItem key={area.area} value={area.area}>
              {area.area}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemGroupUpdateDialog; 