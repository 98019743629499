import React, { createContext, useState, useContext } from 'react';

const PurchaseOrderContext = createContext();

export function PurchaseOrderProvider({ children }) {
  const [ponums, setPonums] = useState([]);
  const [poLines, setPoLines] = useState([]);
  const [searchParams, setSearchParams] = useState({
    ponum: "",
    vendor: "",
  });
  const [selectedPO, setSelectedPO] = useState(null);

  return (
    <PurchaseOrderContext.Provider value={{ 
      ponums,
      setPonums,
      poLines,
      setPoLines,
      searchParams,
      setSearchParams,
      selectedPO,
      setSelectedPO
    }}>
      {children}
    </PurchaseOrderContext.Provider>
  );
}

export function usePurchaseOrder() {
  return useContext(PurchaseOrderContext);
} 