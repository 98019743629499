import React, { createContext, useState, useContext } from 'react';

const ItemGroupsContext = createContext();

export function ItemGroupsProvider({ children }) {
  const [itemGroups, setItemGroups] = useState([]);
  const [searchParams, setSearchParams] = useState({
    group: "",
  });

  return (
    <ItemGroupsContext.Provider value={{ 
      itemGroups, 
      setItemGroups,
      searchParams,
      setSearchParams
    }}>
      {children}
    </ItemGroupsContext.Provider>
  );
}

export function useItemGroups() {
  const context = useContext(ItemGroupsContext);
  if (!context) {
    throw new Error('useItemGroups must be used within an ItemGroupsProvider');
  }
  return context;
} 