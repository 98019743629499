import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import UpdateDialog from "../Components/UpdateDialog";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CreateDialog from "../Components/CreateDialog";
// Optional: Import UUID for unique IDs
// import { v4 as uuidv4 } from 'uuid';

function Receiving() {
  const [isQtyDisabled, setQtyDisabled] = useState(true);
  const [newReceiving, setReceiving] = useState({
    trknum: "",
    ponum: "",
    item: "",
    description: "",
    uom: "",
    qty: "",
  });
  const [isReceiveButtonDisabled, setReceiveButtonDisabled] = useState(true);
  const [isLoadButtonDisabled, setLoadButtonDisabled] = useState(true); // New state for Load button

  useEffect(() => {
    const requiredFields = [
      newReceiving.ponum,
      newReceiving.item,
      newReceiving.qty,
    ];
    setReceiveButtonDisabled(requiredFields.some((field) => field === ""));

    // Additionally, control the Load button (requires ponum and item)
    const loadRequiredFields = [
      newReceiving.ponum,
      newReceiving.item,
    ];
    setLoadButtonDisabled(loadRequiredFields.some((field) => field === ""));
  }, [newReceiving]);

  const itemRef = useRef(null);
  const qtyRef = useRef(null);

  const [receivedItems, setReceivedItems] = useState([]); // To store received inventory

  const [selectedRecord, setSelectedRecord] = useState(null); // To store the selected record

  const reverseInventory = async () => {
    if (!selectedRecord) return;

    const sql = `DELETE FROM inventory WHERE item = '${selectedRecord.item}' AND location = 'location' AND qty = '${selectedRecord.qty}'`;

    try {
      await axios.post("https://ivanchuyko.com/api/query", { sql });

      // Remove the record from the local DataGrid state
      setReceivedItems((prevItems) =>
        prevItems.filter((item) => item.id !== selectedRecord.id)
      );

      notify("Inventory reversed successfully!", "success", 3000);
      setSelectedRecord(null); // Clear selection after reversing
    } catch (error) {
      console.error("Error reversing inventory:", error);
      notify("Failed to reverse inventory.", "error", 3000);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReceiving((prevArea) => ({
      ...prevArea,
      [name]: value,
    }));

    if (name === "item") {
      fetchUOM(value);
      fetchDescription(value);
    }
  };

  const [validationError, setValidationError] = useState("");
  const [uomError, setUomError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [settings, setSettings] = useState([]);
  const [isPOValidationSkipped, setPOValidationSkipped] = useState(false);
  const [poValidationError, setPOValidationError] = useState("");
  const [areFieldsDisabled, setFieldsDisabled] = useState(true);
  const [isPOValidationEnabled, setPOValidationEnabled] = useState(true);

  const fetchSettings = async () => {
    try {
      const response = await axios.get("https://ivanchuyko.com/api/settings");
      setSettings(response.data);

      // Find the setting for "Skip Tracking #"
      const skipTrackingSetting = response.data.find(
        (setting) => setting.name === "Skip Tracking #"
      );

      // Find the setting for "Skip PO Validation"
      const skipPOValidationSetting = response.data.find(
        (setting) => setting.name === "Skip PO Validation"
      );

      // Check if both settings are disabled
      if (
        skipTrackingSetting &&
        skipTrackingSetting.is_enabled === 0 &&
        skipPOValidationSetting &&
        skipPOValidationSetting.is_enabled === 0
      ) {
        setFieldsDisabled(true);
      } else {
        setFieldsDisabled(false);
      }

      if (skipPOValidationSetting && skipPOValidationSetting.is_enabled === 0) {
        setPOValidationSkipped(true);
        setPOValidationEnabled(false); // PO validation is enabled
      } else {
        setPOValidationSkipped(false);
        setPOValidationEnabled(true); // PO validation is not enabled
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleTrknumChange = (event) => {
    const { value } = event.target;
    setReceiving((prevArea) => ({
      ...prevArea,
      trknum: value,
    }));

    if (value) {
      setFieldsDisabled(false);
    } else {
      setFieldsDisabled(true);
    }
  };

  const validatePO = async (ponum) => {
    const sql = `SELECT 'x' FROM po WHERE ponum = '${ponum}'`;

    try {
      const response = await axios.post("https://ivanchuyko.com/api/query", {
        sql,
      });
      if (response.data.length === 0) {
        setPOValidationError("PO number is not valid");
      } else {
        setPOValidationError("");
      }
    } catch (error) {
      console.error("PO Validation error:", error);
      setPOValidationError("Error validating PO number");
    }
  };

  const handlePOBlur = () => {
    if (!isPOValidationSkipped) {
      validatePO(newReceiving.ponum);
    }
  };

  const validateItem = async (item, ponum) => {
    const sql = `SELECT 'x' FROM polines WHERE ponum = '${ponum}' AND item = '${item}'`;

    try {
      const response = await axios.post("https://ivanchuyko.com/api/query", {
        sql,
      });
      if (response.data.length === 0) {
        setValidationError("Item is not valid for the given Purchase Order");
        setQtyDisabled(true);
        itemRef.current.focus();
      } else {
        setQtyDisabled(false);
        setValidationError("");
        setTimeout(() => qtyRef.current.focus(), 0);
      }
    } catch (error) {
      console.error("Validation error:", error);
      setValidationError("Error validating item");
      setQtyDisabled(true);
      itemRef.current.focus();
    }
  };

  const fetchUOM = async (item) => {
    const sql = `SELECT uom FROM items WHERE item = '${item}'`;

    try {
      const response = await axios.post("https://ivanchuyko.com/api/query", {
        sql,
      });
      if (response.data.length > 0) {
        setReceiving((prevArea) => ({
          ...prevArea,
          uom: response.data[0].uom,
        }));
        setUomError("");
      } else {
        setUomError("UOM not found for the given item");
      }
    } catch (error) {
      console.error("Error fetching UOM:", error);
      setUomError("Error fetching UOM");
    }
  };

  const fetchDescription = async (item) => {
    const sql = `SELECT description FROM items WHERE item = '${item}'`;

    try {
      const response = await axios.post("https://ivanchuyko.com/api/query", {
        sql,
      });
      if (response.data.length > 0) {
        setReceiving((prevArea) => ({
          ...prevArea,
          description: response.data[0].description,
        }));
        setDescriptionError("");
      } else {
        setDescriptionError("Description not found for the given item");
      }
    } catch (error) {
      console.error("Error fetching Description:", error);
      setDescriptionError("Error fetching Description");
    }
  };

  const handleItemBlur = () => {
    validateItem(newReceiving.item, newReceiving.ponum);
  };

  const receiveInventory = async () => {
    const sql = `INSERT INTO inventory (item, location, qty) VALUES ('${newReceiving.item}', 'location', '${newReceiving.qty}')`;

    try {
      await axios.post("https://ivanchuyko.com/api/query", { sql });

      // Append the received item to the DataGrid rows
      setReceivedItems((prevItems) => [
        ...prevItems,
        {
          id: Date.now(), // Use a unique ID (timestamp)
          trknum: newReceiving.trknum,
          ponum: newReceiving.ponum,
          item: newReceiving.item,
          description: newReceiving.description,
          uom: newReceiving.uom,
          qty: newReceiving.qty,
        },
      ]);

      clearInputFields();
      notify("Inventory received!", "success", 3000);
    } catch (error) {
      console.error("Error creating area:", error);
      notify("Failed to receive inventory.", "error", 3000);
    }
  };

  const loadData = async () => {
    const { ponum, item } = newReceiving;

    // Validate inputs
    if (!ponum || !item) {
      notify("Please provide both Purchase Order and Part number.", "warning", 3000);
      return;
    }

    // Construct the SQL query to fetch matching records
    const sql = `SELECT item, qty 
                 FROM inventory 
                 WHERE  item = '${item}'`;

    try {
      const response = await axios.post("https://ivanchuyko.com/api/query", { sql });

      if (response.data.length > 0) {
        // Map the received data to match DataGrid's expected format
        const fetchedItems = response.data.map((record, index) => ({
          id: record.id || Date.now() + index, // Ensure each record has a unique ID
          trknum: record.trknum,
          ponum: record.ponum,
          item: record.item,
          description: record.description,
          uom: record.uom,
          qty: record.qty,
        }));

        setReceivedItems(fetchedItems);
        notify("Data loaded successfully!", "success", 3000);
      } else {
        notify("No records found for the provided PO# and Part#.", "warning", 3000);
        setReceivedItems([]); // Clear the DataGrid if no records are found
      }
    } catch (error) {
      console.error("Error loading data:", error);
      notify("Failed to load data. Please try again.", "error", 3000);
    }
  };

  const clearInputFields = () => {
    setReceiving({
      trknum: newReceiving.trknum,
      ponum: newReceiving.ponum,
      item: "",
      description: "",
      uom: "",
      qty: "",
    });
    setValidationError("");
    setUomError("");
    setDescriptionError("");
    setPOValidationError("");
  };

  const columns = [
    { field: "trknum", headerName: "Tracking #", width: 150 },
    { field: "ponum", headerName: "Purchase Order", width: 150 },
    { field: "item", headerName: "Item", width: 150 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "uom", headerName: "UOM", width: 100 },
    { field: "qty", headerName: "Quantity", width: 100 },
  ];

  return (
    <Container maxWidth="xl">
      {/* Input Fields and Buttons */}
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        marginTop={1}
        padding={3}
        boxShadow={3}
        borderRadius={2}
        bgcolor="background.paper"
        height="65px"
      >
        <TextField
          label="Tracking #"
          name="trknum"
          value={newReceiving.trknum}
          onChange={handleTrknumChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          sx={{
            display:
              settings.find((setting) => setting.name === "Skip Tracking #")
                ?.is_enabled === 1
                ? "none"
                : "block",
          }}
        />

        <TextField
          label="Purchase Order"
          name="ponum"
          value={newReceiving.ponum}
          onChange={handleInputChange}
          onBlur={handlePOBlur}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          disabled={areFieldsDisabled}
          error={!!poValidationError}
          helperText={poValidationError}
        />
        <TextField
          label="Item"
          name="item"
          value={newReceiving.item}
          onChange={handleInputChange}
          onBlur={handleItemBlur}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          error={!!validationError}
          helperText={validationError}
          inputRef={itemRef}
          disabled={!isPOValidationEnabled}
        />
        <TextField
          label="Description"
          name="description"
          value={newReceiving.description}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          disabled
        />
        <TextField
          label="UOM"
          name="uom"
          value={newReceiving.uom}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          error={!!uomError}
          helperText={uomError}
          disabled
        />
        <TextField
          label="Qty"
          name="qty"
          value={newReceiving.qty}
          onChange={handleInputChange}
          margin="normal"
          size="small"
          style={{ marginRight: "10px" }}
          disabled={isQtyDisabled || areFieldsDisabled}
          inputRef={qtyRef}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={receiveInventory}
          style={{ marginRight: "10px", textTransform: "none", height: "45px" }}
          disabled={isReceiveButtonDisabled}
        >
          Receive
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={reverseInventory}
          style={{ marginLeft: "10px", textTransform: "none", height: "45px" }}
          disabled={!selectedRecord} // Button enabled only if a record is selected
        >
          Reverse
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={loadData}
          style={{ marginLeft: "10px", textTransform: "none", height: "45px" }}
          disabled={isLoadButtonDisabled} // Disable if PO# or Item is missing
        >
          Load Data
        </Button>
      </Box>

      {/* DataGrid */}
      <Box sx={{ height: 400, width: "100%", marginTop: 2 }}>
        <DataGrid
          rows={receivedItems}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onRowClick={(params) => setSelectedRecord(params.row)} // Handle single row click
          // Removed checkboxSelection and disableSelectionOnClick
          getRowId={(row) => row.id} // Ensure row ID is used correctly
          selectionModel={selectedRecord ? [selectedRecord.id] : []} // Highlight selected row
        />
      </Box>

      <Notification />
    </Container>
  );
}

export default Receiving;
