import React, { useState } from "react";
import { Notification, notify } from "../Components/Notification";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import api from '../services/api';
import ItemUpdateDialog from "../Components/ItemUpdateDialog";
import ItemCreationDialog from "../Components/ItemCreationDialog";
import { useItems } from '../Context/ItemsContext';

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "75vh",
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#f5f2f2",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#eef2f6",
    cursor: "pointer",
  },
  "& .MuiTableRow-root.selected": {
    backgroundColor: "#bfbdbd !important",
  },
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    height: "25px",
    fontSize: "0.9rem",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    backgroundColor: "#ffffff",
    color: "#525151",
    fontSize: "1rem",
    fontWeight: "bold",
    height: "35px",
  }
});

function ItemsScreen() {
  const { items, setItems, searchParams, setSearchParams } = useItems();
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [searchItem] = useState("");
  const [itemToUpdate, setItemToUpdate] = useState({
    item: "",
    description: "",
    item_group: "",
    len: "",
    wid: "",
    hgt: "",
    weight: "",
    is_active: "",
    uom: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newItem, setNewItem] = useState({
    item: "",
    description: "",
    item_group: "",
    len: "",
    wid: "",
    hgt: "",
    weight: "",
    is_active: "1",
    uom: "",
  });

  const fetchItems = async () => {
    try {
      const response = await api.getItems(searchParams.item);
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
      notify("Failed to fetch items data", "error", 3000);
    }
  };

  const clearItems = () => {
    setItems([]);
    setSearchParams({ item: "" });
    notify("Items cleared!", "success", 1000);
  };

  const handleInputChange = (event) => {
    setSearchParams(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const handleUpdate = async () => {
    const { item, description, item_group, uom, len, wid, hgt, weight, is_active } = itemToUpdate;
    try {
      await api.updateItem(item, description, item_group, uom, len, wid, hgt, weight, is_active);
      const updatedItems = items.map((oldItem) =>
        oldItem.item === item ? itemToUpdate : oldItem
      );
      setItems(updatedItems);
      setUpdateDialogOpen(false);
      setSelectedRow(null);
      notify("Item has been updated", "success", 3000);
    } catch (error) {
      console.error("Error updating item:", error);
      notify("Failed to update item", "error", 3000);
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setItemToUpdate(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedItems = items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const formatNumber = (value) => {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

  const handleCreateItem = async () => {
    try {
      await api.createItem(
        newItem.item,
        newItem.description,
        newItem.item_group,
        newItem.uom,
        newItem.len,
        newItem.wid,
        newItem.hgt,
        newItem.weight,
        newItem.is_active
      );
      setCreateDialogOpen(false);
      setNewItem({
        item: "",
        description: "",
        item_group: "",
        len: "",
        wid: "",
        hgt: "",
        weight: "",
        is_active: "1",
        uom: "",
      });
      fetchItems();
      notify("Item created successfully!", "success", 3000);
    } catch (error) {
      console.error("Error creating item:", error);
      notify("Failed to create item", "error", 3000);
    }
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await api.deleteItem(selectedRow);
        setSelectedRow(null);
        fetchItems();
        notify("Item deleted successfully!", "success", 3000);
      } catch (error) {
        console.error("Error deleting item:", error);
        notify("Failed to delete item", "error", 3000);
      }
    }
  };

  return (
    <Container maxWidth="lx">
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
      >
        <Typography
          variant="h6"
          sx={{
            color: "#525151",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Items
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            label="Item"
            name="item"
            value={searchItem}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={fetchItems}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Find
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={clearItems}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Clear
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              if (selectedRow !== null) {
                const foundItem = items.find((item) => item.item === selectedRow);
                if (foundItem) {
                  setItemToUpdate(foundItem);
                  setUpdateDialogOpen(true);
                }
              }
            }}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Modify
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => setCreateDialogOpen(true)}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Create
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleDelete}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
              "&.Mui-disabled": {
                color: "rgba(82, 81, 81, 0.4)",
                backgroundColor: "#f5f2f2",
              }
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Group</TableCell>
                <TableCell>Length</TableCell>
                <TableCell>Width</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>UOM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedItems.map((row) => (
                <TableRow
                  key={row.item}
                  onClick={() => setSelectedRow(row.item)}
                  className={selectedRow === row.item ? 'selected' : ''}
                >
                  <TableCell>{row.item}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.item_group}</TableCell>
                  <TableCell>{formatNumber(row.len)}</TableCell>
                  <TableCell>{formatNumber(row.wid)}</TableCell>
                  <TableCell>{formatNumber(row.hgt)}</TableCell>
                  <TableCell>{formatNumber(row.weight)}</TableCell>
                  <TableCell>{row.is_active}</TableCell>
                  <TableCell>{row.uom}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={items.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[30]}
            sx={{
              backgroundColor: "#ffffff",
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                margin: 0,
              }
            }}
          />
        </StyledTableContainer>
      </Box>

      <ItemUpdateDialog
        open={updateDialogOpen}
        onClose={() => {
          setUpdateDialogOpen(false);
          setItemToUpdate({
            item: "",
            description: "",
            item_group: "",
            len: "",
            wid: "",
            hgt: "",
            weight: "",
            is_active: "",
            uom: "",
          });
        }}
        itemToUpdate={itemToUpdate}
        handleInputChange={handleUpdateInputChange}
        handleUpdate={handleUpdate}
      />
      <ItemCreationDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setNewItem({
            item: "",
            description: "",
            item_group: "",
            len: "",
            wid: "",
            hgt: "",
            weight: "",
            is_active: "1",
            uom: "",
          });
        }}
        newItem={newItem}
        handleInputChange={handleCreateInputChange}
        handleSubmit={handleCreateItem}
      />
      <Notification />
    </Container>
  );
}

export default ItemsScreen;
