// ItemCreationDialog.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from "@mui/material";


const ItemCreationDialog = ({
  open,
  onClose,
  newItem,
  handleInputChange,
  handleSubmit
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Item</DialogTitle>
      <DialogContent>
        <DialogContentText>Please provide details for the new item:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Item"
          name="item"
          value={newItem.item}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={newItem.description}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />
        <TextField
          margin="dense"
          label="Length"
          name="len"
          value={newItem.len}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
        <TextField
          margin="dense"
          label="Width"
          name="wid"
          value={newItem.wid}
          onChange={handleInputChange}
          size="small"
        />
        <TextField
          margin="dense"
          label="Height"
          name="hgt"
          value={newItem.hgt}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
                <TextField
          margin="dense"
          label="Weight"
          name="weight"
          value={newItem.weight}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
        <TextField
          margin="dense"
          label="Active (0 or 1)"
          name="is_active"
          value={newItem.is_active}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
        <TextField
          margin="dense"
          label="UOM"
          name="uom"
          value={newItem.uom}
          onChange={handleInputChange}
          size="small"
          style={{marginRight:"8px"}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemCreationDialog;
