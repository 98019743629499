import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const ItemUpdateDialog = ({ open, onClose, itemToUpdate, handleInputChange, handleUpdate }) => {
  if (!itemToUpdate) {
    return null;
  }

  const formatNumber = (value) => {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Item</DialogTitle>
      <DialogContent>
        <TextField
          label="Item"
          name="item"
          value={itemToUpdate.item || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
          disabled
        />
        <TextField
          label="Description"
          name="description"
          value={itemToUpdate.description || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Group"
          name="item_group"
          value={itemToUpdate.item_group || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Length"
          name="len"
          value={formatNumber(itemToUpdate.len) || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Width"
          name="wid"
          value={formatNumber(itemToUpdate.wid) || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Height"
          name="hgt"
          value={formatNumber(itemToUpdate.hgt) || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Weight"
          name="weight"
          value={formatNumber(itemToUpdate.weight) || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="Active"
          name="active"
          value={itemToUpdate.is_active || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
        <TextField
          label="UOM"
          name="uom"
          value={itemToUpdate.uom || ""}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleUpdate} color="primary">Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemUpdateDialog;
