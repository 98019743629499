import React, { useState } from "react";
import axios from "axios";
import { Notification, notify } from "../Components/Notification";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { usePurchaseOrder } from '../Context/PurchaseOrderContext';
import api from '../services/api';
import UpdateDialog from "../Components/UpdateDialog";

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "75vh",
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#f5f2f2",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#eef2f6",
    cursor: "pointer",
  },
  "& .MuiTableRow-root.selected": {
    backgroundColor: "#bfbdbd !important",
  },
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    height: "25px",
    fontSize: "0.9rem",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    backgroundColor: "#ffffff",
    color: "#525151",
    fontSize: "1rem",
    fontWeight: "bold",
    height: "35px",
  }
});

function PurchaseOrdersScreen() {
  const { 
    ponums, 
    setPonums, 
    poLines, 
    setPoLines,
    searchParams,
    setSearchParams,
    selectedPO,
    setSelectedPO
  } = usePurchaseOrder();

  const getProgressColor = (percentage) => {
    if (percentage === 0) {
      return 'rgb(199,199,199)'; // Black for 0%
    }
    if (percentage <= 50) {
      // From red to yellow (1-50%)
      const green = Math.round((percentage / 50) * 255);
      return `rgb(255, ${green}, 0)`;
    } else {
      // From yellow to green (51-100%)
      const red = Math.round(((100 - percentage) / 50) * 255);
      return `rgb(${red}, 255, 0)`;
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newPO, setNewPO] = useState({
    ponum: "",
    vendor: ""
  });
  const [selectedPoLine, setSelectedPoLine] = useState(null);
  const [poLineDialogOpen, setPoLineDialogOpen] = useState(false);
  const [poLineDeleteDialogOpen, setPoLineDeleteDialogOpen] = useState(false);
  const [poLineToUpdate, setPoLineToUpdate] = useState({
    ponum: "",
    item: "",
    expqty: "",
    recqty: ""
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);

  const fetchPurchaseOrders = async () => {
    try {
      const ponumParam = searchParams.ponum ? `'${searchParams.ponum}'` : 'NULL';
      const vendorParam = searchParams.vendor ? `'${searchParams.vendor}'` : 'NULL';      
      const response = await api.getPurchaseOrders(ponumParam, vendorParam);
      setPonums(response.data[0]);
    } catch (error) {
      console.error("Error fetching purchase orders:", error);
      notify("Failed to fetch purchase order data", "error", 3000);
    }
  };

  const clearPurchaseOrders = () => {
    setPonums([]);
    setPoLines([]);
    setSearchParams({ ponum: "", vendor: "" });
    setSelectedPO(null);
    setSelectedRow(null);
    setSelectedPoLine(null);
    notify("Purchase orders cleared!", "success", 1000);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRowClick = async (id) => {
    setSelectedRow(id);
    const selected = ponums[id];
    setSelectedPO(selected);
    try {
      const response = await api.getPoLines(selected.ponum);
      console.log('PO Lines Response:', response.data);
      console.log('PO Lines Data:', response.data[0]);
      setPoLines(response.data[0]);
    } catch (error) {
      console.error("Error fetching PO lines:", error);
      notify("Failed to fetch PO lines", "error", 3000);
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  const handleDelete = async () => {
    if (selectedRow !== null) {
      try {
        const poToDelete = ponums[selectedRow];
        await api.deletePurchaseOrder(poToDelete.ponum);
        notify("Purchase order deleted successfully!", "success", 3000);
        fetchPurchaseOrders();
      } catch (error) {
        console.error("Error deleting purchase order:", error);
        notify("Failed to delete purchase order", "error", 3000);
      }
    }
  };

  const handleCreateSubmit = async (newPO) => {
    try {
      await api.createPurchaseOrder(newPO);
      notify("Purchase order created successfully!", "success", 3000);
      setCreateDialogOpen(false);
      fetchPurchaseOrders();
    } catch (error) {
      console.error("Error creating purchase order:", error);
      notify("Failed to create purchase order", "error", 3000);
    }
  };

  const handleDialogInputChange = (e) => {
    const { name, value } = e.target;
    setNewPO(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePoLineUpdate = async () => {
    try {
      await api.updatePoLine(
        poLineToUpdate.ponum,
        poLineToUpdate.item,
        poLineToUpdate.expqty
      );
      const response = await api.getPoLines(selectedPO.ponum);
      setPoLines(response.data[0]);
      setPoLineDialogOpen(false);
      notify("PO Line updated successfully", "success", 3000);
    } catch (error) {
      console.error("Error updating PO line:", error);
      notify("Failed to update PO line", "error", 3000);
    }
  };

  const handlePoLineDelete = async () => {
    try {
      const lineToDelete = poLines[selectedPoLine];
      await api.deletePoLine(lineToDelete.ponum, lineToDelete.item);
      const response = await api.getPoLines(selectedPO.ponum);
      setPoLines(response.data[0]);
      setPoLineDeleteDialogOpen(false);
      setSelectedPoLine(null);
      notify("PO Line deleted successfully", "success", 3000);
    } catch (error) {
      console.error("Error deleting PO line:", error);
      notify("Failed to delete PO line", "error", 3000);
    }
  };

  const handlePoLineCreate = async (newLine) => {
    try {
      if (!selectedPO) {
        notify("No PO selected", "error", 3000);
        return;
      }
      
      await api.createPoLine(selectedPO.ponum, newLine.item, newLine.expqty);
      const response = await api.getPoLines(selectedPO.ponum);
      setPoLines(response.data[0]);
      setPoLineDialogOpen(false);
      notify("PO Line created successfully", "success", 3000);
    } catch (error) {
      console.error("Error creating PO line:", error);
      notify("Failed to create PO line", "error", 3000);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Container maxWidth="lx">
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
      >
        <Typography
          variant="h6"
          sx={{
            color: "#525151",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Purchase Orders
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            label="PO Number"
            name="ponum"
            value={searchParams.ponum}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <TextField
            label="Vendor"
            name="vendor"
            value={searchParams.vendor}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={fetchPurchaseOrders}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Find
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={clearPurchaseOrders}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Clear
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleCreate}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Create
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleDelete}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
              "&.Mui-disabled": {
                color: "rgba(82, 81, 81, 0.4)",
                backgroundColor: "#f5f2f2",
              }
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          height: "calc(50vh - 150px)",
          width: "100%",
          marginBottom: "10px"
        }}
      >
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Purchase Order</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Total Expected Qty</TableCell>
                <TableCell>Total Received Qty</TableCell>
                <TableCell>Progress (%)</TableCell>
                <TableCell>Progress Bar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ponums
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((ponum, index) => (
                  <TableRow 
                    key={index} 
                    onClick={() => handleRowClick(index)}
                    className={selectedRow === index ? 'selected' : ''}
                  >
                    <TableCell>{ponum.ponum}</TableCell>
                    <TableCell>{ponum.vendor}</TableCell>
                    <TableCell>{ponum.total_expqty}</TableCell>
                    <TableCell>{ponum.total_rcvqty}</TableCell>
                    <TableCell sx={{
                      fontWeight: 'bold',
                      color: (theme) => {
                        const rcvqty = Number(ponum.total_rcvqty) || 0;
                        const expqty = Number(ponum.total_expqty) || 0;
                        const percentage = expqty === 0 ? 0 : Math.round((rcvqty / expqty) * 100);
                        return getProgressColor(percentage);
                      }
                    }}>
                      {(() => {
                        const rcvqty = Number(ponum.total_rcvqty) || 0;
                        const expqty = Number(ponum.total_expqty) || 0;
                        return expqty === 0 ? 0 : Math.round((rcvqty / expqty) * 100);
                      })()}%
                    </TableCell>
                    <TableCell>
                      <progress value={ponum.total_rcvqty} max={ponum.total_expqty}></progress>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <TablePagination
          component="div"
          count={ponums.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[15]}
        />
      </Box>

      {selectedPO && (
        <Box
          sx={{
            height: "calc(50vh - 100px)",
            width: "100%"
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#525151",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              marginBottom: "10px"
            }}
          >
            PO Lines for {selectedPO.ponum}
          </Typography>
          <StyledTableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Purchase Order</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Expected Quantity</TableCell>
                  <TableCell>Received Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {poLines.map((line, index) => (
                  <TableRow 
                    key={index} 
                    onClick={() => setSelectedPoLine(index)}
                    className={selectedPoLine === index ? 'selected' : ''}
                  >
                    <TableCell>{line.ponum}</TableCell>
                    <TableCell>{line.item}</TableCell>
                    <TableCell>{line.expqty}</TableCell>
                    <TableCell>{line.rcvqty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      )}

      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="end"
        marginTop={1}
        height="45px"
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            if (selectedPoLine !== null) {
              setPoLineDeleteDialogOpen(true);
            }
          }}
          disabled={selectedPoLine === null || !selectedPO}
          sx={{
            color: "#525151",
            typography: "h2",
            fontSize: "1.25rem",
            fontWeight: "bold",
            fontFamily: "Arial",
            backgroundColor: "#f5f2f2",
            "&:hover": {
              backgroundColor: "#bfbdbd",
            },
            "&.Mui-disabled": {
              color: "rgba(82, 81, 81, 0.4)",
              backgroundColor: "#f5f2f2",
            }
          }}
          style={{
            marginRight: "10px",
            textTransform: "none",
            height: "45px",
          }}
        >
          Delete Line
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            if (selectedPoLine !== null) {
              setPoLineToUpdate(poLines[selectedPoLine]);
              setPoLineDialogOpen(true);
            }
          }}
          disabled={selectedPoLine === null || !selectedPO}
          sx={{
            color: "#525151",
            typography: "h2",
            fontSize: "1.25rem",
            fontWeight: "bold",
            fontFamily: "Arial",
            backgroundColor: "#f5f2f2",
            "&:hover": {
              backgroundColor: "#bfbdbd",
            },
            "&.Mui-disabled": {
              color: "rgba(82, 81, 81, 0.4)",
              backgroundColor: "#f5f2f2",
            }
          }}
          style={{
            marginRight: "10px",
            textTransform: "none",
            height: "45px",
          }}
        >
          Modify Line
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setPoLineToUpdate({ ponum: selectedPO?.ponum || "", item: "", expqty: "" });
            setPoLineDialogOpen(true);
          }}
          disabled={!selectedPO}
          sx={{
            color: "#525151",
            typography: "h2",
            fontSize: "1.25rem",
            fontWeight: "bold",
            fontFamily: "Arial",
            backgroundColor: "#f5f2f2",
            "&:hover": {
              backgroundColor: "#bfbdbd",
            },
            "&.Mui-disabled": {
              color: "rgba(82, 81, 81, 0.4)",
              backgroundColor: "#f5f2f2",
            }
          }}
          style={{
            marginRight: "10px",
            textTransform: "none",
            height: "45px",
          }}
        >
          Add Line
        </Button>
      </Box>
      

      <UpdateDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          setNewPO({ ponum: "", vendor: "" });
        }}
        title="Create Purchase Order"
        contentText="Enter the details for the new purchase order"
        fields={[
          {
            label: "PO Number",
            name: "ponum",
            value: newPO.ponum,
            autoFocus: true,
          },
          {
            label: "Vendor",
            name: "vendor",
            value: newPO.vendor,
          }
        ]}
        handleInputChange={handleDialogInputChange}
        handleSubmit={() => handleCreateSubmit(newPO)}
      />

      <UpdateDialog
        open={poLineDialogOpen}
        onClose={() => {
          setPoLineDialogOpen(false);
          setPoLineToUpdate({ ponum: "", item: "", expqty: "" });
        }}
        title={poLineToUpdate.item ? "Update PO Line" : "Add PO Line"}
        contentText={poLineToUpdate.item ? "Update the PO line details:" : "Enter the new PO line details:"}
        fields={[
          {
            label: "PO Number",
            name: "ponum",
            value: selectedPO?.ponum || "",
            disabled: true,
            style: {marginRight: "10px"}
          },
          {
            label: "Item",
            name: "item",
            value: poLineToUpdate.item,
            //disabled: !!poLineToUpdate.item,
          },
          {
            label: "Expected Quantity",
            name: "expqty",
            value: poLineToUpdate.expqty,
            type: "number",
          },
        ]}
        handleInputChange={(e) =>
          setPoLineToUpdate({ ...poLineToUpdate, [e.target.name]: e.target.value })
        }
        handleSubmit={() => {
          // Check if this is an existing line being updated
          const existingLine = poLines.find(line => 
            line.ponum === selectedPO.ponum && line.item === poLineToUpdate.item
          );

          if (existingLine) {
            // If the line exists, update it
            handlePoLineUpdate();
          } else {
            // If the line doesn't exist, create new
            handlePoLineCreate(poLineToUpdate);
          }
        }}
      />

      <Dialog open={poLineDeleteDialogOpen} onClose={() => setPoLineDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this PO line?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPoLineDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePoLineDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Notification />
    </Container>
  );
}

export default PurchaseOrdersScreen;
