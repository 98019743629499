import React, { useState } from "react";
import { useInventory } from "../Context/InventoryContext";
import { Notification } from "../Components/Notification";
import { styled } from "@mui/system";
import {
  Button,
  Container,
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";
import api from '../services/api';

const StyledTableContainer = styled(TableContainer)({
  maxHeight: "75vh",
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#f5f2f2",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#eef2f6",
    cursor: "pointer",
  },
  "& .MuiTableRow-root.selected": {
    backgroundColor: "#bfbdbd !important",
  },
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    height: "25px",
    fontSize: "0.9rem",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    backgroundColor: "#ffffff",
    color: "#525151",
    fontSize: "1rem",
    fontWeight: "bold",
    height: "35px",
  }
});

function InventoryScreen() {
  const { inventory, setInventory, searchParams, setSearchParams } = useInventory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const fetchInventory = async () => {
    try {
      const response = await api.getInventory(
        searchParams.item ? `'${searchParams.item}'` : 'NULL',
        searchParams.location ? `'${searchParams.location}'` : 'NULL'
      );
      setInventory(response.data);
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
  };

  const clearInventory = () => {
    setInventory([]);
    setSearchParams({ item: "", location: "" });
    setSelectedRow(null);
  };

  const handleRowClick = (id) => {
    setSelectedRow(id === selectedRow ? null : id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const formatNumber = (value) => {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

  return (
    <Container maxWidth="lx">
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
      >
        <Typography
          variant="h6"
          sx={{
            color: "#525151",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Inventory
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            label="Item"
            name="item"
            value={searchParams.item}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <TextField
            label="Location"
            name="location"
            value={searchParams.location}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={fetchInventory}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Find
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={clearInventory}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>UOM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventory
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={`${row.item}-${row.location}`}
                    onClick={() => handleRowClick(`${row.item}-${row.location}`)}
                    className={selectedRow === `${row.item}-${row.location}` ? 'selected' : ''}
                  >
                    <TableCell>{row.item}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.area}</TableCell>
                    <TableCell>{formatNumber(row.qty)}</TableCell>
                    <TableCell>{row.uom}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={inventory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            sx={{
              backgroundColor: "#ffffff",
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                margin: 0,
              }
            }}
          />
        </StyledTableContainer>
      </Box>

      <Notification />
    </Container>
  );
}

export default InventoryScreen; 