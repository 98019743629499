import axios from 'axios';

const BASE_URL = 'https://ivanchuyko.com/api';

// Create axios instance with default config
const axiosInstance = axios.create();

// Add request interceptor to add auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log('Using token:', token); // For debugging
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const api = {
  // Auth endpoints (don't use axiosInstance for login)
  login: async (username, password) => {
    return axios.post(`${BASE_URL}/login`, { username, password });
  },

  // Query endpoint (generic)
  executeQuery: async (sql) => {
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Dashboard endpoints using SQL query
  getDashboardStats: async () => {
    const statsQuery = `
      SELECT 
        (SELECT COUNT(*) FROM items) as item_count,
        (SELECT COUNT(*) FROM locations WHERE active = 1) as location_count,
        (SELECT COUNT(*) FROM po) as po_count
    `;
    return axiosInstance.post(`${BASE_URL}/query`, { sql: statsQuery });
  },

  // Items endpoints
  getItems: async (item = '') => {
    const sql = item 
      ? `SELECT * FROM items WHERE item LIKE '%${item}%'`
      : 'SELECT * FROM items';
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Locations endpoints
  getLocations: async (location = '') => {
    const sql = location 
      ? `SELECT * FROM locations WHERE location LIKE '%${location}%'`
      : 'SELECT * FROM locations';
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  createLocation: async (locationData) => {
    const {
      location,
      area,
      len,
      wid,
      hgt,
      volume,
      active,
      storable,
      pickable,
    } = locationData;

    const sql = `INSERT INTO locations (location, area, status, len, wid, hgt, volume, active, storable, pickable) 
      VALUES (
        '${location}', 
        '${area}',
        'Empty', 
        ${len || 0}, 
        ${wid || 0}, 
        ${hgt || 0}, 
        ${volume || 0}, 
        ${active || 0}, 
        ${storable || 0}, 
        ${pickable || 0}
      )`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  updateLocation: async (locationData) => {
    const {
      location,
      area,
      len,
      wid,
      hgt,
      volume,
      active,
      storable,
      pickable,
    } = locationData;

    const sql = `UPDATE locations SET 
      location = '${location}', 
      area = '${area}', 
      len = ${len || 0}, 
      wid = ${wid || 0}, 
      hgt = ${hgt || 0}, 
      volume = ${volume || 0}, 
      active = ${active || 0}, 
      storable = ${storable || 0}, 
      pickable = ${pickable || 0} 
      WHERE location = '${location}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  deleteLocation: async (location) => {
    const sql = `DELETE FROM locations WHERE location = '${location}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Item Groups endpoints
  getItemGroups: async (group = '') => {
    const sql = group 
      ? `SELECT * FROM item_groups WHERE group_name LIKE '%${group}%'`
      : 'SELECT * FROM item_groups';
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Stored Procedures
  getInventory: async (itemParam, locationParam) => {
    const sql = `CALL get_inventory(${itemParam}, ${locationParam})`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  getPurchaseOrders: async (ponumParam, vendorParam) => {
    const sql = `CALL get_purchase_orders(${ponumParam}, ${vendorParam})`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  getPoLines: async (ponum) => {
    const sql = `CALL get_po_lines('${ponum}')`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Areas endpoints
  getAreas: async (area = '') => {
    const sql = area 
      ? `SELECT * FROM areas WHERE area LIKE '%${area}%'`
      : 'SELECT * FROM areas';
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  updateArea: async (area, description) => {
    const sql = `UPDATE areas SET description = '${description}' WHERE area = '${area}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  createArea: async (area, description) => {
    const sql = `INSERT INTO areas (area, description) VALUES ('${area}', '${description}')`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  deleteArea: async (area) => {
    const sql = `DELETE FROM areas WHERE area = '${area}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Item Groups endpoints
  deleteItemGroup: async (group_name) => {
    const sql = `DELETE FROM item_groups WHERE item_group = '${group_name}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  updateItemGroup: async (item_group, description, area1, area2, mod_date) => {
    const sql = `
      UPDATE item_groups 
      SET description = '${description}',
          area1 = '${area1}',
          area2 = ${area2 ? `'${area2}'` : 'NULL'},
          mod_date = '${mod_date}'
      WHERE item_group = '${item_group}'
    `;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  updateItem: async (item, description, item_group, uom, len, wid, hgt, weight, is_active) => {
    const sql = `UPDATE items SET description = '${description}', item_group = '${item_group}', uom = '${uom}', len = ${len}, wid = ${wid}, hgt = ${hgt}, weight = ${weight}, is_active = ${is_active} WHERE item = '${item}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  deleteItem: async (item) => {
    const sql = `DELETE FROM items WHERE item = '${item}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  createItemGroup: async (itemGroup) => {
    const sql = `
      INSERT INTO item_groups (item_group, description, area1, area2, mod_date) 
      VALUES (
        '${itemGroup.item_group}',
        '${itemGroup.description}',
        '${itemGroup.area1}',
        ${itemGroup.area2 ? `'${itemGroup.area2}'` : 'NULL'},
        '${itemGroup.mod_date}'
      )
    `;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  createPurchaseOrder: async (poData) => {
    const sql = `INSERT INTO po (ponum, vendor) VALUES ('${poData.ponum}', '${poData.vendor}')`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  deletePurchaseOrder: async (ponum) => {
    const sql = `DELETE FROM po WHERE ponum = '${ponum}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  createPoLine: async (ponum, item, expqty) => {
    const sql = `INSERT INTO polines (ponum, item, expqty) VALUES ('${ponum}', '${item}', ${expqty})`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  updatePoLine: async (ponum, item, expqty) => {
    const sql = `UPDATE polines SET expqty = ${expqty} WHERE ponum = '${ponum}' AND item = '${item}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  deletePoLine: async (ponum, item) => {
    const sql = `DELETE FROM polines WHERE ponum = '${ponum}' AND item = '${item}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  // Settings endpoints
  createBackup: async () => {
    const sql = `BACKUP DATABASE wms TO DISK = 'C:\\backup\\wms_backup.bak'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  restoreBackup: async () => {
    const sql = `RESTORE DATABASE wms FROM DISK = 'C:\\backup\\wms_backup.bak'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  getSettings: async () => {
    const sql = 'SELECT * FROM settings';
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  updateSetting: async (name, is_enabled) => {
    const sql = `UPDATE settings SET is_enabled=${is_enabled ? 1 : 0} WHERE name='${name}'`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },

  createItem: async (item, description, item_group, uom, len, wid, hgt, weight, is_active) => {
    const sql = `
      INSERT INTO items (item, description, item_group, uom, len, wid, hgt, weight, is_active) 
      VALUES (
        '${item}',
        '${description}',
        '${item_group}',
        '${uom}',
        ${len || 0},
        ${wid || 0},
        ${hgt || 0},
        ${weight || 0},
        ${is_active || 1}
      )`;
    return axiosInstance.post(`${BASE_URL}/query`, { sql });
  },
};

// Add response interceptor for debugging
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('API Response:', response);
    return response;
  },
  (error) => {
    console.error('API Error:', error.response || error);
    return Promise.reject(error);
  }
);

export default api; 