import React, { createContext, useState, useContext } from 'react';
import api from '../services/api';
import { notify } from '../Components/Notification';

const LocationsContext = createContext();

export function LocationsProvider({ children }) {
  const [locations, setLocations] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const fetchLocations = async (searchLocation = '') => {
    try {
      const response = await api.getLocations(searchLocation);
      setLocations(response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const createLocation = async (locationData) => {
    try {
      await api.createLocation(locationData);
      await fetchLocations();
      notify("Location created successfully", "success", 3000);
      return true;
    } catch (error) {
      console.error("Error creating location:", error);
      notify("Error creating location", "error", 3000);
      return false;
    }
  };

  const updateLocation = async (locationData) => {
    try {
      await api.updateLocation(locationData);
      setLocations(prev => prev.map(loc => 
        loc.location === locationData.location ? locationData : loc
      ));
      notify("Location has been updated", "success", 3000);
      return true;
    } catch (error) {
      console.error("Error updating location:", error);
      notify("Error updating location", "error", 3000);
      return false;
    }
  };

  const deleteLocation = async (location) => {
    try {
      await api.deleteLocation(location);
      setLocations(prev => prev.filter(loc => loc.location !== location));
      setSelectedRow(null);
      notify("Location deleted successfully", "success", 3000);
      return true;
    } catch (error) {
      console.error("Error deleting location:", error);
      notify("Error deleting location", "error", 3000);
      return false;
    }
  };

  const clearLocations = () => {
    setLocations([]);
    setSelectedRow(null);
    notify("Locations cleared!", "success", 1000);
  };

  return (
    <LocationsContext.Provider
      value={{
        locations,
        selectedRow,
        setSelectedRow,
        fetchLocations,
        createLocation,
        updateLocation,
        deleteLocation,
        clearLocations,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
}

export const useLocations = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error('useLocations must be used within a LocationsProvider');
  }
  return context;
}; 