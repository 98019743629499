import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import api from '../services/api';
import { Notification, notify } from "../Components/Notification";
import CreateUserDialog from "../Components/CreateUserDialog";

function Login({ setIsLoggedIn }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.login(username, password);

      if (response.data.message === "Login successful" && response.data.token) {
        // Store the JWT token securely
        localStorage.setItem("authToken", response.data.token);
        setIsLoggedIn(true);
        notify("Login successful!", "success", 3000);
      } else {
        notify("An error occurred during login. Please try again.", "error", 3000);
      }
    } catch (error) {
      console.error("Login error:", error);
      notify("Invalid username or password.", "error", 3000);
    }
  };

  return (
    <>
      <Notification />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "linear-gradient(to right, #2ce1f5, #fa7af6)",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          fontWeight="bold"
          color="#555"
          sx={{ mt: 10, mb: 10 }}
        >
          Receive. Store. Ship.
        </Typography>

        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={350}
            padding={3}
            boxShadow={2}
            borderRadius={3}
            backgroundColor="rgba(255, 255, 255, 0.2)"
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              align="center"
              color="#555"
            >
              WMS Login
            </Typography>

            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                size="small"
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="small"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1, textTransform: "none" }}
              >
                Sign In
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  mb: 2,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                onClick={() => setDialogOpen(true)}
              >
                Create User
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Create User Dialog */}
      <CreateUserDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </>
  );
}

export default Login;
