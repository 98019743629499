// ItemCreationDialog.js
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import api from "./services/api";

const LocationCreationDialog = ({
  open,
  onClose,
  newLocation,
  handleInputChange,
  handleSubmit,
}) => {
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await api.getAreas();
        setAreas(response.data);
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    };
    if (open) {
      fetchAreas();
    }
  }, [open]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleInputChange({
      target: {
        name,
        value: checked ? 1 : 0,
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Location</DialogTitle>
      <DialogContent>
        <DialogContentText>Please provide details for the new location:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Location"
          name="location"
          value={newLocation.location}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />
        <FormControl fullWidth margin="dense" size="small">
          <InputLabel>Area</InputLabel>
          <Select
            name="area"
            value={newLocation.area}
            onChange={handleInputChange}
            label="Area"
          >
            {areas.map((area) => (
              <MenuItem key={area.area} value={area.area}>
                {area.area}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Length"
          name="len"
          value={newLocation.len}
          onChange={handleInputChange}
          size="small"
          style={{ marginRight: "8px" }}
        />
        <TextField
          margin="dense"
          label="Width"
          name="wid"
          value={newLocation.wid}
          onChange={handleInputChange}
          size="small"
          style={{ marginRight: "8px" }}
        />
        <TextField
          margin="dense"
          label="Height"
          name="hgt"
          value={newLocation.hgt}
          onChange={handleInputChange}
          size="small"
          style={{ marginRight: "8px" }}
        />
        <TextField
          margin="dense"
          label="Volume"
          name="volume"
          value={newLocation.volume}
          onChange={handleInputChange}
          fullWidth
          size="small"
        />

        {/* Flag Checkboxes */}
        <FormControlLabel
          control={
            <Checkbox
              checked={newLocation.active === 1}
              onChange={handleCheckboxChange}
              name="active"
            />
          }
          label="Active"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={newLocation.storable === 1}
              onChange={handleCheckboxChange}
              name="storable"
            />
          }
          label="Storable"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={newLocation.pickable === 1}
              onChange={handleCheckboxChange}
              name="pickable"
            />
          }
          label="Pickable"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationCreationDialog;
