import React, { useState } from "react";
import { useLocations } from './contexts/LocationsContext';
import { Notification, } from "./Components/Notification";
import LocationCreationDialog from "./LocationCreationDialog";
import LocationUpdateDialog from "./LocationUpdateDialog";
import {
  Button,
  Container,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";



const StyledTableContainer = styled(TableContainer)({
  maxHeight: "75vh",
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: "#f5f2f2",
  },
  "& .MuiTableRow-root:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiTableRow-root:hover": {
    backgroundColor: "#eef2f6",
    cursor: "pointer",
  },
  "& .MuiTableRow-root.selected": {
    backgroundColor: "#bfbdbd !important",
  },
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    height: "25px",
    fontSize: "0.9rem",
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    backgroundColor: "#ffffff",
    color: "#525151",
    fontSize: "1rem",
    fontWeight: "bold",
    height: "35px",
  }
});


function LocationsScreen() {
  const {
    locations,
    selectedRow,
    setSelectedRow,
    fetchLocations,
    createLocation,
    updateLocation,
    deleteLocation,
    clearLocations,
  } = useLocations();

  const [searchParams, setSearchParams] = useState({
    location: ""
  });

  const [newLocation, setNewLocation] = useState({
    location: "",
    area: "",
    status: "",
    len: "",
    wid: "",
    hgt: "",
    volume: "",
    active: 0,
    storable: 0,
    pickable: 0
  });
  
  const [openDialog, setOpenDialog] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [locationToUpdate, setLocationToUpdate] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(15);

  const handleSubmit = async () => {
    const success = await createLocation(newLocation);
    if (success) {
      setNewLocation({
        location: "",
        area: "",
        status: "",
        len: "",
        wid: "",
        hgt: "",
        volume: "",
        active: 0,
        storable: 0,
        pickable: 0
      });
      setCreateDialogOpen(false);
    }
  };

  const handleUpdate = async () => {
    const success = await updateLocation(locationToUpdate);
    if (success) {
      setUpdateDialogOpen(false);
      setSelectedRow(null);
      setLocationToUpdate(null);
    }
  };

  const handleConfirmDelete = async () => {
    if (locationToDelete) {
      const success = await deleteLocation(locationToDelete.location);
      if (success) {
        setOpenDialog(false);
        setLocationToDelete(null);
        setSelectedRow(null);
        await fetchLocations(searchParams.location);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? null : index); // Toggle selection
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const locationToDelete = locations.find(loc => loc.location === selectedRow);
      if (locationToDelete) {
        setLocationToDelete(locationToDelete);
        setOpenDialog(true);
      }
    }
  };
  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setLocationToUpdate((prevLocation) => ({
      ...prevLocation,
      [name]: value,
    }));
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setLocationToDelete(null);
  };

  const handleModify = () => {
    if (selectedRow !== null) {
      const locationToModify = locations.find(loc => loc.location === selectedRow);
      if (locationToModify) {
        setLocationToUpdate(locationToModify);
        setUpdateDialogOpen(true);
      }
    }
  };

  const handleCreate = () => {
    setCreateDialogOpen(true);
  };

  // Handle closing the CreateDialog form
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const formatNumber = (value) => {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

  // Add separate handler for create dialog inputs
  const handleCreateInputChange = (event) => {
    const { name, value } = event.target;
    setNewLocation(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

 

  return (
    <Container maxWidth="lx">
      <Box
        mb={1}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
      >
        <Typography
          variant="h6"
          sx={{
            color: "#525151",
            fontSize: "1.5rem",
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Locations
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            label="Location"
            name="location"
            value={searchParams.location}
            onChange={handleInputChange}
            margin="normal"
            variant="standard"
            size="small"
            style={{ marginRight: "10px" }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={() => fetchLocations(searchParams.location)}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Find
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={clearLocations}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Clear
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleModify}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Modify
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleDelete}
            disabled={selectedRow === null}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Delete
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleCreate}
            sx={{
              color: "#525151",
              typography: "h2",
              fontSize: "1.25rem",
              fontWeight: "bold",
              fontFamily: "Arial",
              backgroundColor: "#f5f2f2",
              "&:hover": {
                backgroundColor: "#bfbdbd",
              },
            }}
            style={{
              marginRight: "10px",
              textTransform: "none",
              height: "45px",
            }}
          >
            Create
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <StyledTableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Length</TableCell>
                <TableCell>Width</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>Volume</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Storable</TableCell>
                <TableCell>Pickable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {locations
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.location}
                    onClick={() => handleRowClick(row.location)}
                    className={selectedRow === row.location ? 'selected' : ''}
                  >
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.area}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{formatNumber(row.len)}</TableCell>
                    <TableCell>{formatNumber(row.wid)}</TableCell>
                    <TableCell>{formatNumber(row.hgt)}</TableCell>
                    <TableCell>{formatNumber(row.volume)}</TableCell>
                    <TableCell>{row.active}</TableCell>
                    <TableCell>{row.storable}</TableCell>
                    <TableCell>{row.pickable}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={locations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          sx={{
            backgroundColor: "#ffffff",
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              margin: 0,
            }
          }}
        />
      </Box>

      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {locationToDelete?.location}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <LocationCreationDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        newLocation={newLocation}
        handleInputChange={handleCreateInputChange}
        handleSubmit={handleSubmit}
      />

      <Notification />
      <LocationUpdateDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        locationToUpdate={locationToUpdate}
        handleInputChange={handleUpdateInputChange}
        handleUpdate={handleUpdate}
      />
    </Container>
  );
}

export default LocationsScreen;
