import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import api from '../services/api';
import { notify } from '../Components/Notification';

function Home() {
  const [dashboardData, setDashboardData] = useState(null);
  const [recentActivity, setRecentActivity] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const statsResponse = await api.getDashboardStats();
        console.log('Dashboard response:', statsResponse.data);

        if (statsResponse.data && statsResponse.data[0]) {
          const stats = statsResponse.data[0];
          setDashboardData({
            totalItems: stats.item_count || 0,
            activeLocations: stats.location_count || 0,
            openPOs: stats.po_count || 0
          });
        } else {
          console.error('Unexpected response format:', statsResponse.data);
          setDashboardData({
            totalItems: 0,
            activeLocations: 0,
            openPOs: 0
          });
        }

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        notify('Failed to load dashboard data', 'error', 3000);
        setDashboardData({
          totalItems: 0,
          activeLocations: 0,
          openPOs: 0
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
        
        <Grid container spacing={3}>
          {/* Stats Cards */}
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 140,
              }}
            >
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total Items
              </Typography>
              <Typography component="p" variant="h4">
                {dashboardData?.totalItems || 0}
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 140,
              }}
            >
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Active Locations
              </Typography>
              <Typography component="p" variant="h4">
                {dashboardData?.activeLocations || 0}
              </Typography>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 140,
              }}
            >
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Open POs
              </Typography>
              <Typography component="p" variant="h4">
                {dashboardData?.openPOs || 0}
              </Typography>
            </Paper>
          </Grid>

          {/* Recent Activity */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Recent Activity
              </Typography>
              {recentActivity.length > 0 ? (
                recentActivity.map((activity, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Typography variant="body1">
                      {activity.description}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(activity.timestamp).toLocaleString()}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No recent activity
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Home;