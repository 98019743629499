import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Box,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./App.css";
import Login from "./Pages/Login";
import Home from "./Pages/home";
import Receiving from "./Pages/Receiving";
import ItemsScreen from "./Pages/Items";
import LocationsScreen from "./Locations";
import AreasScreen from "./Pages/Areas";
import PurchaseOrdersScreen from "./Pages/PurchaseOrders";
import ItemGroups from "./Pages/ItemGroups";
import SettingsScreen from "./Pages/Settings";
import { Notification } from "./Components/Notification";
import Sidebar from "./Pages/Sidebar";
import InventoryScreen from "./Pages/Inventory";
import { InventoryProvider } from './Context/InventoryContext';
import { PurchaseOrderProvider } from './Context/PurchaseOrderContext';
import { ItemsProvider } from './Context/ItemsContext';
import { ItemGroupsProvider } from './Context/ItemGroupsContext';
import { LocationsProvider } from './contexts/LocationsContext';
import { AreasProvider } from './Context/AreasContext';
import { NotificationProvider } from './contexts/NotificationContext';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        </Routes>
      </Router>
    );
  }

  return (
    <NotificationProvider>
      <AreasProvider>
        <InventoryProvider>
          <PurchaseOrderProvider>
            <ItemsProvider>
              <ItemGroupsProvider>
                <LocationsProvider>
                  <Router>
                    <Notification />
                    <div className="App">
                      <CssBaseline />
                      <AppBar
                        position="fixed"
                        sx={{ 
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                          '& .MuiToolbar-root': { 
                            minHeight: '48px',
                            '@media (min-width: 600px)': {
                              minHeight: '48px',
                            },
                          },
                        }}
                      >
                        <Toolbar>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={toggleSidebar}
                            sx={{ 
                              mr: 2,
                              padding: '8px',
                            }}
                          >
                            <MenuIcon sx={{ fontSize: '1.2rem' }} />
                          </IconButton>
                          <Typography variant="h6" noWrap>
                            WMS
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Sidebar
                        sidebarOpen={sidebarOpen}
                        toggleSidebar={toggleSidebar}
                        handleLogout={handleLogout}
                      />
                      <Box
                        component="main"
                        sx={{
                          flexGrow: 1,
                          bgcolor: "background.default",
                          p: 3,
                          ml: sidebarOpen ? 0 : -30,
                          transition: "margin-left 0.3s",
                          width: sidebarOpen ? "calc(100% - 240px)" : "100%",
                        }}
                      >
                        <Toolbar />
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/receiving" element={<Receiving />} />
                          <Route path="/itemsScreen" element={<ItemsScreen />} />
                          <Route path="/locationsScreen" element={<LocationsScreen />} />
                          <Route path="/areasScreen" element={<AreasScreen />} />
                          <Route path="/settingsScreen" element={<SettingsScreen />} />
                          <Route path="/purchaseOrdersScreen" element={<PurchaseOrdersScreen />} />
                          <Route path="/itemGroupsScreen" element={<ItemGroups />} />
                          <Route path="/inventory" element={<InventoryScreen />} />
                        </Routes>
                      </Box>
                    </div>
                  </Router>
                </LocationsProvider>
              </ItemGroupsProvider>
            </ItemsProvider>
          </PurchaseOrderProvider>
        </InventoryProvider>
      </AreasProvider>
    </NotificationProvider>
  );
}

export default App;
